// Print styles only applicable for products page

@media print {

    * {
        color: #000000 !important;
    }

    .js-mobile-btn, .js-lang-btn, .footer, .brands, .button, .link, .form {
        @apply hidden;
    }

    .header {
        @apply border-0;
    }

    .carousel__controls, .gallery-thumbnails, .gallery__controls, .tab-menu {
        @apply hidden;
    }

    .carousel .swiper-slide {
        width: 33.33% !important;
    }

    .gallery .swiper-slide {
        width: 50% !important;
    }

    .gallery-with-thumbnails .swiper-slide {
        width: 100% !important;
    }

    .swiper-wrapper {
        @apply flex-wrap;
    }

    .swiper-slide {
        @apply mb-10;
    }

    .swiper-slide:last-child {
        @apply mb-0;
    }

    .tabs-menu {
        @apply hidden;
    }

    .accordion-tabs-link {
        @apply bg-grey-200 border-0;
    }

    .accordion-tabs-link:after {
        @apply hidden;
    }

    .accordion-tabs-content {
        max-height: 10000px;
        @apply opacity-100;
    }

    .comparison__item {
        width: 33.33% !important;
    }

    .download-list__item {
        @apply list-disc list-inside border-0;
    }

    .download-list__item:first-child {
        @apply border-0;
    }

    .download-list__link {
        @apply inline p-0;
    }

    .download-list__icon {
        @apply hidden;
    }

    .typography ul li {
        @apply list-disc list-inside pl-0;
    }

    .typography ul li:before {
        @apply hidden;
    }

    // Backgrounds

    .bg-gradient:after {
        @apply hidden;
    }

    .bg-navy-700,
    .bg-navy-800,
    .bg-cream-100 {
        @apply bg-white;
    }

    .padding + .padding {
        @apply pt-0;
    }

    // Typography

    h1 {
        @apply text-navy-700;
    }
}
